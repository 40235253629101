.reviews {
  width: 100%;
  padding: 2rem 1rem;
  background: rgb(7, 5, 5, 0.9);
}

.reviews-box {
  max-width: 900px;
  margin: auto;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  padding: 45px;
}

@media screen and (max-width: 980px) {
  .reviews-box {
    max-width: 900px;
    margin: auto;
    display: grid;
    justify-content: center;
    align-content: center;
    grid-gap: 10px;
    grid-template-columns: repeat(1, 1fr);
    padding: 50px;
  }
}
