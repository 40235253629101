.footer {
  width: 100%;
  padding: 1rem 0;
  background: rgb(7, 5, 5, 0.5);
}

.footer-container {
  max-width: 1140px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.footer .left {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 3rem;
}

.footer .right {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 4rem 3rem;
  margin: auto;
}

.footer h4 {
  font-size: 1.4rem;
  padding: 0.5rem 0.2rem;
  text-shadow: #000 2px 2px 5px;
}

.footer p {
  font-size: 1.2rem;
  padding: 0.5rem 0.2rem;
  text-shadow: #000 2px 2px 5px;
}

.footer .location {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.2rem;
}
.footer .location p {
  padding: 0.5rem 0.2rem;
}
.footer .location h4 {
  padding: 0.5rem 0.2rem;
}

.social {
  padding: 0.5rem 0.2rem;
}

@media screen and (max-width: 640px) {
  .footer-container {
    grid-template-columns: 1fr;
  }

  .right .social {
    margin: auto;
    padding: 10px;
  }

  .footer h4 {
    font-size: 1rem;
    padding: 0.5rem 0.2rem;
  }

  .footer p {
    font-size: 1rem;
    padding: 0.5rem 0.2rem;
  }
  .footer .right {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 3rem 1rem;
    margin: auto;
  }
}
