#video {
  position: fixed;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero {
  height: 100vh;
  width: 100%;
  object-fit: contain;
  background: rgba(0, 0, 0, 0.2);
}

.hero .content {
  text-align: center;
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  top: 35vh;
}

.hero .content h1 {
  font-size: 4rem;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px #000;
  margin-bottom: 0rem;
}

.hero p {
  font-size: 1.7rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 50px;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  font-style: italic;
}

.hero .btn {
  margin: 1rem 0.2rem;
}

/* mobile formatting */

@media screen and (max-width: 840px) {
  .hero .content h1 {
    font-size: 2.2rem;
    margin-bottom: -10px;
  }

  .hero .content p {
    font-size: 1.2rem;
    margin-bottom: 2.2rem;
    font-weight: 600;
  }
}
