.services {
  width: 100%;
  padding: 2rem 1rem 4rem 2rem;
  background: rgb(7, 5, 5, 0.9);
}

.services p {
  color: #eee;
  padding: 1rem 0;
  font-size: 1.2rem;
}

.card-container {
  max-width: 1140px;
  margin: auto;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
  padding: 1px 10px 2px 0px;
}

.card-container .card {
  border: 1px solid #777777;
  color: #777777;
  padding: 150px 0px;
}

.card {
  text-align: center;
  padding: 1rem;
  border-radius: 20px;
}

.card:hover {
  background-color: #141414;
}

.card h3 {
  font-size: 1.8rem;
  padding: 3px 7px;
  text-shadow: 2px 2px 2px #000;
}

.card .bar {
  border-bottom: 1px solid #eee;
  width: 10%;
  margin: 1.5rem auto;
  display: block;
}

.card .btn {
  display: block;
  width: 80%;
  margin: 2rem auto;
}

@media screen and (max-width: 980px) {
  .card-container {
    max-width: 98%;
    margin: auto;
    grid-template-columns: 1fr;
  }
}

.drivewayz {
  background-image: url("../assets/driveway-img.jpg");
  background-size: 500px 500px;
  background-position: center;
}

.stepz {
  background-image: url("../assets/steps-img.jpg");
  background-size: 500px 500px;
  background-position: center;
}

.patioz {
  background-image: url("../assets/patio-img.jpg");
  background-position: center;
  background-size: 500px 500px;
}

.wallz {
  background-image: url("../assets/retaining-wall-img.jpg");
  background-position: center;
  background-size: 500px 500px;
}

.deckz {
  background-image: url("../assets/deck-img.jpg");
  background-position: center;
  background-size: 500px 500px;
}

.lightingz {
  background-image: url("../assets/lighting-img.jpg");
  background-position: center;
  background-size: 500px 500px;
}

.fencez {
  background-image: url("../assets/fence-img.jpg");
  background-position: center;
  background-size: 500px 500px;
}
