@import url(https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&family=Cuprum:wght@700&family=Homenaje&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Rock+Salt&family=Teko:wght@300;400;500;600;700&display=swap);
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Cuprum", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(0, 0, 0);
}

h1,
h4,
p,
a {
  color: #fff;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn {
  padding: 12px 32px;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
  text-shadow: 1px 1px 1px #000;
  box-shadow: 1px 1px 3px #000;
  border-radius: 10px;
}

.btn-light {
  background: rgba(238, 238, 238, 0.5);
}
.btn:hover {
  background: rgba(238, 238, 238, 0.8);
}

.header {
  position: fixed;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  z-index: 10;
}

.header-title {
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: 600;
}

.header-title-logo {
  width: 105px;
  height: auto;

  margin: -15px 0px -15px 20px;
}
.header-title-text {
  padding: 0px 10px;
  text-shadow: 1px 1px 1px #000;
}

.header-bg {
  background-color: rgba(0, 0, 0, 0.95);
  transition: 0.5s;
}

.nav-menu {
  display: flex;
}

.nav-menu li {
  padding: 0 1rem;
}

.nav-menu a {
  font-size: 1.2rem;
  font-weight: 500;
}

.hamburger {
  display: none;
}

@media screen and (max-width: 1240px) {
  .hamburger {
    display: block;
  }

  .nav-menu {
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: -100%;
    text-align: center;
    width: 100%;
    height: 100vh;
    transition: 0.3s;
    z-index: -1;
    background: rgba(0, 0, 0, 0.9);
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-menu li {
    padding: 1rem 0;
  }

  .nav-menu a {
    font-size: 2rem;
  }
}

@media screen and (max-width: 980px) {
  .header-title {
    font-size: 1.5rem;
    text-transform: uppercase;
  }
  .header-title-logo {
    width: 75px;
    height: auto;
    margin: -5px 0;
  }
  .header-title-text {
    display: none;
  }
}

#video {
  position: fixed;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero {
  height: 100vh;
  width: 100%;
  object-fit: contain;
  background: rgba(0, 0, 0, 0.2);
}

.hero .content {
  text-align: center;
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  top: 35vh;
}

.hero .content h1 {
  font-size: 4rem;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px #000;
  margin-bottom: 0rem;
}

.hero p {
  font-size: 1.7rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 50px;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  font-style: italic;
}

.hero .btn {
  margin: 1rem 0.2rem;
}

/* mobile formatting */

@media screen and (max-width: 840px) {
  .hero .content h1 {
    font-size: 2.2rem;
    margin-bottom: -10px;
  }

  .hero .content p {
    font-size: 1.2rem;
    margin-bottom: 2.2rem;
    font-weight: 600;
  }
}

.footer {
  width: 100%;
  padding: 1rem 0;
  background: rgb(7, 5, 5, 0.5);
}

.footer-container {
  max-width: 1140px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.footer .left {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 3rem;
}

.footer .right {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 4rem 3rem;
  margin: auto;
}

.footer h4 {
  font-size: 1.4rem;
  padding: 0.5rem 0.2rem;
  text-shadow: #000 2px 2px 5px;
}

.footer p {
  font-size: 1.2rem;
  padding: 0.5rem 0.2rem;
  text-shadow: #000 2px 2px 5px;
}

.footer .location {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.2rem;
}
.footer .location p {
  padding: 0.5rem 0.2rem;
}
.footer .location h4 {
  padding: 0.5rem 0.2rem;
}

.social {
  padding: 0.5rem 0.2rem;
}

@media screen and (max-width: 640px) {
  .footer-container {
    grid-template-columns: 1fr;
  }

  .right .social {
    margin: auto;
    padding: 10px;
  }

  .footer h4 {
    font-size: 1rem;
    padding: 0.5rem 0.2rem;
  }

  .footer p {
    font-size: 1rem;
    padding: 0.5rem 0.2rem;
  }
  .footer .right {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 3rem 1rem;
    margin: auto;
  }
}

.services {
  width: 100%;
  padding: 2rem 1rem 4rem 2rem;
  background: rgb(7, 5, 5, 0.9);
}

.services p {
  color: #eee;
  padding: 1rem 0;
  font-size: 1.2rem;
}

.card-container {
  max-width: 1140px;
  margin: auto;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
  padding: 1px 10px 2px 0px;
}

.card-container .card {
  border: 1px solid #777777;
  color: #777777;
  padding: 150px 0px;
}

.card {
  text-align: center;
  padding: 1rem;
  border-radius: 20px;
}

.card:hover {
  background-color: #141414;
}

.card h3 {
  font-size: 1.8rem;
  padding: 3px 7px;
  text-shadow: 2px 2px 2px #000;
}

.card .bar {
  border-bottom: 1px solid #eee;
  width: 10%;
  margin: 1.5rem auto;
  display: block;
}

.card .btn {
  display: block;
  width: 80%;
  margin: 2rem auto;
}

@media screen and (max-width: 980px) {
  .card-container {
    max-width: 98%;
    margin: auto;
    grid-template-columns: 1fr;
  }
}

.drivewayz {
  background-image: url(/static/media/driveway-img.e745e1cb.jpg);
  background-size: 500px 500px;
  background-position: center;
}

.stepz {
  background-image: url(/static/media/steps-img.0b5bd095.jpg);
  background-size: 500px 500px;
  background-position: center;
}

.patioz {
  background-image: url(/static/media/patio-img.4ae30dab.jpg);
  background-position: center;
  background-size: 500px 500px;
}

.wallz {
  background-image: url(/static/media/retaining-wall-img.a6bf4d4a.jpg);
  background-position: center;
  background-size: 500px 500px;
}

.deckz {
  background-image: url(/static/media/deck-img.7a905fd6.jpg);
  background-position: center;
  background-size: 500px 500px;
}

.lightingz {
  background-image: url(/static/media/lighting-img.d3640934.jpg);
  background-position: center;
  background-size: 500px 500px;
}

.fencez {
  background-image: url(/static/media/fence-img.cb78e6ca.jpg);
  background-position: center;
  background-size: 500px 500px;
}

.product {
  width: 100%;

  margin: auto;
  display: grid;
  grid-template-columns: (2, 1fr);
  height: 100%;
  background: rgb(7, 5, 5, 0.9);
  padding: 5px;
}

.product-inner {
  width: 100%;
  margin: auto;
  display: grid;
  max-width: 1140px;
}

.product .left {
  text-align: center;
  margin: auto;
  padding: 2rem 4rem;
  max-width: 900px;
}

.product .left p {
  margin: 1.2rem 0;
  font-size: 1.2rem;
  padding: 10px 0;
}

.product .right {
  width: 100%;
}

.right .img-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
  align-items: center;
  text-align: center;
}

.right .img {
  max-width: 100%;
  border: 1px solid #333;
}

.right .top {
  grid-column: 1 / span 6;
  grid-row: 1;
  z-index: 6;
}

.right .bottom {
  grid-column: 7/ -1;
  grid-row: 1;
  z-index: 5;
}

@media screen and (max-width: 1140px) {
  .product {
    grid-template-columns: 1fr;
  }

  .product .img {
    max-width: 95%;
  }
}

.blurb-img {
  background: rgb(7, 5, 5, 0.9);
  height: 100%;
  width: 100%;
  position: relative;
}

.blurb-img:before {
  content: "";
  /* background: url("../assets/small-brick-stairs.jpg") no-repeat center
    center/cover; */
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.blurb-img .heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 2rem;
}

.blurb-img h1 {
  font-size: 1.5rem;
}

.blurb-img p {
  font-size: 1.5rem;
  text-align: center;
  max-width: 1000px;
  padding: 10px 20px;
  text-shadow: 1px 1px #000;
}

@media screen and (max-width: 640px) {
  .blurb-img h1 {
    font-size: 1.3rem;
  }
  .blurb-img p {
    font-size: 1.3rem;
    padding: 1px 2px;
  }
}

.reviews {
  width: 100%;
  padding: 2rem 1rem;
  background: rgb(7, 5, 5, 0.9);
}

.reviews-box {
  max-width: 900px;
  margin: auto;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  padding: 45px;
}

@media screen and (max-width: 980px) {
  .reviews-box {
    max-width: 900px;
    margin: auto;
    display: grid;
    justify-content: center;
    align-content: center;
    grid-gap: 10px;
    grid-template-columns: repeat(1, 1fr);
    padding: 50px;
  }
}

.hero-img {
  background: rgba(0, 0, 0, 0.2);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.1) 10%,
    rgba(0, 0, 0, 0.6) 100%
  );
  height: 30vh;
  width: 100%;
  position: relative;
}

.hero-img:before {
  content: "";
  background: url(/static/media/mike-truck-header.2d0fab15.jpg) no-repeat center
    center/cover;
  height: 100%;

  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero-img .heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-img h1 {
  font-size: 2.2rem;
  text-shadow: 0.5px 0.5px 5px #000;
  margin-bottom: -18px;
}

.hero-img p {
  font-size: 1.4rem;
  font-style: italic;
  text-shadow: 0.5px 0.5px 5px #000;
}

@media screen and (max-width: 640px) {
  .hero-img h1 {
    font-size: 1.8rem;
  }
  .hero-img p {
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.services2 {
  width: 100%;
  padding: 2rem 1rem 1rem 1rem;
  background: rgb(7, 5, 5, 0.9);
}

.services p,
h1 {
  color: #eee;
  padding: 12px 5px;
  font-size: 1.5rem;
  text-align: center;
}

.contact-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-box {
  padding: 10px 0px;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .services h1 {
    font-size: 1.4rem;
    padding: 0 10px;
    text-align: center;
  }
  .services p {
    font-size: 1.3rem;
    padding: 1rem 0;
  }
}

