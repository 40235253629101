.blurb-img {
  background: rgb(7, 5, 5, 0.9);
  height: 100%;
  width: 100%;
  position: relative;
}

.blurb-img:before {
  content: "";
  /* background: url("../assets/small-brick-stairs.jpg") no-repeat center
    center/cover; */
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.blurb-img .heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 2rem;
}

.blurb-img h1 {
  font-size: 1.5rem;
}

.blurb-img p {
  font-size: 1.5rem;
  text-align: center;
  max-width: 1000px;
  padding: 10px 20px;
  text-shadow: 1px 1px #000;
}

@media screen and (max-width: 640px) {
  .blurb-img h1 {
    font-size: 1.3rem;
  }
  .blurb-img p {
    font-size: 1.3rem;
    padding: 1px 2px;
  }
}
