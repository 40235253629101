.hero-img {
  background: rgba(0, 0, 0, 0.2);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.1) 10%,
    rgba(0, 0, 0, 0.6) 100%
  );
  height: 30vh;
  width: 100%;
  position: relative;
}

.hero-img:before {
  content: "";
  background: url("../assets/mike-truck-header.jpg") no-repeat center
    center/cover;
  height: 100%;

  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero-img .heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-img h1 {
  font-size: 2.2rem;
  text-shadow: 0.5px 0.5px 5px #000;
  margin-bottom: -18px;
}

.hero-img p {
  font-size: 1.4rem;
  font-style: italic;
  text-shadow: 0.5px 0.5px 5px #000;
}

@media screen and (max-width: 640px) {
  .hero-img h1 {
    font-size: 1.8rem;
  }
  .hero-img p {
    font-size: 1.2rem;
    font-weight: 600;
  }
}
