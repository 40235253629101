.product {
  width: 100%;

  margin: auto;
  display: grid;
  grid-template-columns: (2, 1fr);
  height: 100%;
  background: rgb(7, 5, 5, 0.9);
  padding: 5px;
}

.product-inner {
  width: 100%;
  margin: auto;
  display: grid;
  max-width: 1140px;
}

.product .left {
  text-align: center;
  margin: auto;
  padding: 2rem 4rem;
  max-width: 900px;
}

.product .left p {
  margin: 1.2rem 0;
  font-size: 1.2rem;
  padding: 10px 0;
}

.product .right {
  width: 100%;
}

.right .img-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
  align-items: center;
  text-align: center;
}

.right .img {
  max-width: 100%;
  border: 1px solid #333;
}

.right .top {
  grid-column: 1 / span 6;
  grid-row: 1;
  z-index: 6;
}

.right .bottom {
  grid-column: 7/ -1;
  grid-row: 1;
  z-index: 5;
}

@media screen and (max-width: 1140px) {
  .product {
    grid-template-columns: 1fr;
  }

  .product .img {
    max-width: 95%;
  }
}
