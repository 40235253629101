.services2 {
  width: 100%;
  padding: 2rem 1rem 1rem 1rem;
  background: rgb(7, 5, 5, 0.9);
}

.services p,
h1 {
  color: #eee;
  padding: 12px 5px;
  font-size: 1.5rem;
  text-align: center;
}

.contact-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-box {
  padding: 10px 0px;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .services h1 {
    font-size: 1.4rem;
    padding: 0 10px;
    text-align: center;
  }
  .services p {
    font-size: 1.3rem;
    padding: 1rem 0;
  }
}
