@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&family=Cuprum:wght@700&family=Homenaje&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Rock+Salt&family=Teko:wght@300;400;500;600;700&display=swap");

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Cuprum", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(0, 0, 0);
}

h1,
h4,
p,
a {
  color: #fff;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn {
  padding: 12px 32px;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
  text-shadow: 1px 1px 1px #000;
  box-shadow: 1px 1px 3px #000;
  border-radius: 10px;
}

.btn-light {
  background: rgba(238, 238, 238, 0.5);
}
.btn:hover {
  background: rgba(238, 238, 238, 0.8);
}
